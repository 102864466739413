import styled, { keyframes, css } from 'styled-components'
import { orange, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type ContainerProps = {
  isModal?: () => void;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  background-color: ${white};

  &.hero {
    background-color: transparent;

    p {
      color: ${white};
    }
  }

  p {
    font-family: 'Sora';
  }

  input {
    height: 48px;
    border-radius: 8px;
  }

  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 90vh;
      padding: 40px 24px;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      transition: 0.5s ease-in-out;
      animation: ${animatedModal} 0.5s ease-in-out forwards;
      overflow: auto;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 64.5px;
      }
    `
  )}

  .form--default {
    label {
      color: #6A6C72;
      margin-bottom: 4px;
    }
  }

  &.hero {
    .form--default {
      label {
        color: ${white};
        margin-bottom: 4px;
      }
      .radio-check {
        border-color: ${white};
      }
      .form-input-check:checked  {
        ~ label::before {
          background: transparent;
          border: 1px solid ${white};
        }
      }
      .radio-contato {
        {
          input:checked + {
            .radio-check {
              border-color: ${white};

              &:after {
                background: ${white};
              }
            }
          }
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 115px;
      padding-right: 115px;
    }

    a {
      text-decoration: underline ${orange.extra};
    }

    img {
      &.sucesso {
        max-width: 48px;
      }
    }
  }

  &.error {
    img {
      max-width: 80px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .error {
    label,
    span,
    p {
      color: #F56A50;
    }

    input:not([type='range']) {
      background: #FEF0ED !important;
    }
    .corretora::placeholder {
      color: #F56A50;
    }
  }

  &.hero {
    .error {
      span,
  
      input:not([type='range']) {
        background: #F7D0C8 !important;
      }
      .corretora::placeholder {
        color: #F56A50;
        background: #F7D0C8 !important;
      }
    }
  }

  input:not([type='range']) {
    font-weight: 600;

    &:focus {
      outline: none;
    }
  }
`
export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
`

import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { OParceiroParaSimplificarSection, Button } from './style'
import UmaContaDigitalJSON from '../../assets/data/uma-conta-digital.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

type ItemProps = {
  icon: string;
  description: string;
  OrangeDS?: boolean;
}

const UmaContaDigital = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <OParceiroParaSimplificarSection>
      <div className='container'>
        <div className='row align-items-center justify-content-center justify-content-lg-between'>
          <div className='col-12 col-md-6 col-lg-6 mt-xl-5 pr-0 order-1 order-md-2'>
            <div className='text-div text-xl-left pl-0'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/dobra3-solucoes-pj/image.webp'
                altDescription='Smartphone com o Super App Inter Empresas em destaque, ao lado do cartão empresarial do Inter.'
                arrayNumbers={[ 300, 300, 400, 500 ]}
                className='image'
              />
            </div>
          </div>
          <div className='col-12 col-md-6 order-2 order-md-1'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-36 lh-xl-50 fw-600 font-sora text-orange--extra mb-3 mb-lg-4'>
              Conta Digital gratuita, sem burocracia e atendimento humanizado.
            </h2>
            <p className='fs-12 lh-16 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>
              Vantagens exclusivas como Pix ilimitado, boletos gratuitos por mês, programa de pontos e muito mais. Dá só uma olhada em nossos principais produtos:
            </p>

            { UmaContaDigitalJSON.map((item: ItemProps) => (
              <div key={item.icon}>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <div>
                    <OrangeIcon size='MD' color='#FF7A00' icon={item.icon} className='mr-3' />
                  </div>
                  <p className='fs-12 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
              ))}
            <p className='fs-12 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 mb-0'>E muito mais!</p>
            <div className='mt-4 mt-lg-4'>
              <Button className='d-flex justify-content-center justify-content-lg-start mt-md-5'>
                <a
                  href='https://conta-digital-pj.bancointer.com.br/login?utm_source=ebook&utm_medium=botao&utm_campaign=aquisicao_ebook_pj_botao&utm_term=conta-digital-pj&utm_content=seu_negocio_no_topo'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_03',
                      section_name: 'Uma Conta Digital gratuita, sem burocracia e atendimento humanizado.',
                      element_action: 'click button',
                      element_name: 'Abrir conta Inter Empresas',
                      redirect_url: 'https://conta-digital-pj.bancointer.com.br/login?utm_source=ebook&utm_medium=botao&utm_campaign=aquisicao_ebook_pj_botao&utm_term=conta-digital-pj&utm_content=seu_negocio_no_topo',
                    })
                  }}
                  className='btn btn-orange--extra btn--lg fs-14 fw-700 rounded-3 mw-100'
                >
                  Abrir conta Inter Empresas
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </OParceiroParaSimplificarSection>
  )
}

export default UmaContaDigital

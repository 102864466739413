import React, { MouseEvent } from 'react'

import successImg from '../../assets/images/success.png'

import { FormSent, CloseButton } from '../style'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

type OpenAccountFormProps = {
  handleReturn: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Sent = ({
  handleReturn,
}: OpenAccountFormProps) => {
  return (
    <FormSent
      className='d-flex position-relative align-items-center justify-content-center py-3 px-3'
    >
      <CloseButton className='border-0 bg-transparent pt-4' onClick={handleReturn}>
        <Close width='20' height='20' color='orange--extra' />
      </CloseButton>

      <div className='col-12 text-center px-0'>
        <div className='col-12 mx-auto text-center mb-4'>
          <img src={successImg} alt='Sucesso' className='sucesso' />
        </div>

        <p className='fs-24 lh-30 text-grayscale--500 fw-600 font-sora'>
          Parabéns, seu cadastro foi realizado com sucesso!
        </p>
        <p className='fs-14 lh-16 fs-md-16 lh-md-19 text-grayscale--400 text-center'>
          Mas caso não possa realizar o download agora, fique tranquilo! Em pouco tempo você receberá o eBook na sua caixa de e-mail.
        </p>
        <a
          href='https://marketing.bancointer.com.br/empresas/documentos/E-book-produtos-interEmpresas1.pdf'
          target='blank'
          className='btn btn-orange--extra rounded-2 fs-14 fw-700 text-none mr-4 mb-3 mb-md-0'
        >
          Fazer download
        </a>
      </div>
    </FormSent>
  )
}

export default Sent

import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

// Components
import BaixarEbookForm from '../../components/BaixarEbookFormv2/_index'
import ImageWebp from 'src/components/ImageWebp'

import { FormDesktopContainer, FormMobile, Section } from './style'

export const SeuNegocioNoTopo = () => {
  const width = useWidth()

  const arrayNumbers: number[] = [
    300, 300, 350, 400,
  ]

  return (
    <>
      <Section>
        <div className='container'>
          <div className='row align-items-md-center justify-content-between'>
            <div className='col-12 col-md-6 col-xl-5'>
              <div className='d-flex justify-content-center mt-md-5 mb-md-5'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/solucoes-pj-hero-imagem/image.webp'
                  altDescription='Smartphone com o Super App Inter Empresas em destaque, ao lado do cartão empresarial do Inter.'
                  arrayNumbers={arrayNumbers}
                  className='image'
                />
              </div>
              <h1 className='font-sora fs-22 fs-md-24 fs-xl-32 lh-26 lh-md-30 lh-xl-40 fw-600 mt-3 text-orange--extra'>
                <span className='d-md-block d-lg-inline'>Seu negócio no topo:</span> conheça as soluções Inter Empresas!
              </h1>
              <p className='fs-12 fs-md-14 fs-xl-18 lh-14 lh-md-20 lh-lg-22 text-grayscale--500 mb-3 mb-0'>
                Banking, Cartões, Gestão de Cobranças, Crédito e muito mais. Saiba como a conta PJ Inter Empresas simplifica sua rotina empresarial!
              </p>
            </div>
            {width >= WIDTH_MD &&
              <div className='col-md-6 form-hero'>
                <FormDesktopContainer>
                  <BaixarEbookForm
                    cpfStatus='hero'
                    htmlForSim='hero-sim'
                    htmlForNao='hero-nao'
                    formHero=''
                    nameAccept='conta-digital-pf-black-hero'
                    theme='orange'
                  />
                </FormDesktopContainer>
              </div>
            }
          </div>
        </div>
      </Section>
      {width < WIDTH_MD &&
        <FormMobile>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <BaixarEbookForm
                  cpfStatus='hero'
                  htmlForSim='hero-sim'
                  htmlForNao='hero-nao'
                  formHero='hero'
                  nameAccept='conta-digital-pf-black-hero'
                />
              </div>
            </div>
          </div>
        </FormMobile>
      }
    </>
  )
}

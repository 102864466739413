import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'

const bgImage = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pj-hero-background/image.webp'

export const Section = styled.section`
  background-color: ${orange[200]};
  .form-hero {
      a {
        color: ${white};
      }
    }
  
  @media ${device.tablet} {
    background-image: url(${bgImage});
    background-position: 120% 50%;
    background-size: 300px 500px;
    background-repeat: no-repeat;

    .image {
      margin: 0 auto;
      display: flex;

      @media ${device.tablet} {
        margin: 0;
      }
    }
    .form-hero {
      a {
        color: ${orange.extra};
      }
    }

}

  @media ${device.desktopLG} {
    background-image: url(${bgImage});
    padding-top: 64px;
    padding-bottom: 64px;
    background-position: 95% 50%;
  }

  @media ${device.desktopXL} {
    background-image: url(${bgImage});
    padding-top: 96px;
    padding-bottom: 96px;
  }

  @media ${device.desktopXXXL} {
    background-image: url(${bgImage});
  }
`

export const FormMobile = styled.section`
  background-color: ${orange.extra};

  a {
    color: ${white};
  }
`

export const FormDesktopContainer = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 16px;

  form {
    label {
      color: ${grayscale[500]} !important;
    }
  }
`

import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import BaixeAgoraJSON from '../../assets/data/baixe-agora.json'
import { Section } from './style'

type SimplifiqueAAberturaProps = {
  setIsOpen: (isOpen: boolean) => void;
};
interface IIndicateJson {
  icon: string;
  title: string;
  description: string;
}

const BaixeAgora = ({ setIsOpen }: SimplifiqueAAberturaProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_02',
    section_name: 'Simplifique a aberturado seu CNPJ e regularizeo seu negócio.',
    element_action: 'click button',
    element_name: 'Baixar eBook gratuito',
  }

  return (
    <Section>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mx-auto mb-xl-4'>
            <h2 className='fs-20 lh-26 fs-md-28 lh-md-34 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-center fw-600 font-sora text-grayscale--500 mb-3'>
              Baixe agora o eBook “Seu negócio no topo” e transforme sua empresa.
            </h2>
            <p className='fs-14 lh-16 fs-md-16 lh-md-19 fs-xl-24 lh-xl-30 text-center text-grayscale--500 mb-4'>
              Confira abaixo as principais soluções do Inter Empresas que estão presentes no eBook e irão te ajudar no dia a dia dos negócios:
            </p>
          </div>

          <div className='row d-flex'>
            {BaixeAgoraJSON.map((data: IIndicateJson) => (
              <div key={data.icon} className='d-flex col-12 col-md-6 mb-4 align-items-center'>
                <div className='col-12 rounded-3 h-100 box'>
                  <div className='d-flex align-items-center mb-3 '>
                    <OrangeIcon size='MD' color='#EA7100' icon={data.icon} />
                    <div>
                      <p className='fs-16 lh-19 fs-xl-24 lh-xl-30 fw-700 text-grayscale--500 mb-0 ml-3'>{data.title}</p>
                    </div>
                  </div>
                  <p className='fs-16 lh-16 fs-xl-24 lh-xl-30 text-grayscale--500 mb-0'>{data.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className='col-12 text-center'>
            <button
              className='btn btn-orange--extra btn--lg text-none fs-14 fw-700 rounded-3 mt-4'
              onClick={() => {
                setIsOpen(true)
                sendDatalayerEvent(dataLayer)
              }}
              type='button'
            >
              Baixar eBook gratuito
            </button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default BaixeAgora

import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import BaixarEbookForm from './components/BaixarEbookForm/_index'

import { SeuNegocioNoTopo } from './sections/hero/_index'
import UmaContaDigital from './sections/uma-conta-digital/_index'
import BaixeAgora from './sections/baixe-agora/_index'

import pageContext from './pageContext.json'
import { Section } from './style'

const GlobalAccountBusiness = () => {
  const domReady = useDomReady()

  const [ isOpen, setIsOpen ] = useState(false)

  const baixeOLivroModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <BaixarEbookForm
        closeModal={() => setIsOpen(false)}
        cpfStatus='modal'
        htmlForSim='modal-sim'
        htmlForNao='modal-nao'
        nameAccept='conta-digital-pf-black-modal'
      />
    </Modal>
  )

  return (
    <Layout pageContext={pageContext}>
      {baixeOLivroModal}
      <Section>
        <SeuNegocioNoTopo />
        <BaixeAgora setIsOpen={setIsOpen} />
        <UmaContaDigital />
      </Section>
    </Layout>
  )
}

export default GlobalAccountBusiness

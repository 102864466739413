import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: white;

  .box {
    background-color: ${orange.light};
    padding: 20px 32px;

    @media ${device.desktopXL} {
      padding: 40px;
    }
  }
`

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  h1, h2 {
    font-family: 'Citrina';
  }
  section {
    padding: 40px 0;

  @media ${device.tablet} {
    padding: 48px 0;
  }

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXL} {
    padding: 96px;
  }
  }
`
